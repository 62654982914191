import styled from "styled-components";
import media from "styled-media-query";
import { SearchAlt2 } from "styled-icons/boxicons-regular/SearchAlt2";
import { ChevronRight } from "styled-icons/octicons/ChevronRight";
import { ChevronLeft } from "styled-icons/octicons/ChevronLeft";

import { Link } from "gatsby";

export const SearchIcon = styled(SearchAlt2)`
  color: var(--esx-red);
  height: 2rem;
  width: 2rem;
`;
export const ArrowRight = styled(ChevronRight)`
  color: #fafafa;
  height: 1rem;
`;
export const ArrowLeft = styled(ChevronLeft)`
  color: #fafafa;
  height: 1rem;
`;
export const SectionWrapper = styled.section`
  padding: 2rem var(--default-padding-desktop) 0 var(--default-padding-desktop);
  ${media.lessThan("medium")`
    padding: 2rem var(--default-padding-mobile) 0 var(--default-padding-mobile);
  `}
`;
export const PostItemsWrapper = styled.section`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 var(--default-padding-desktop) var(--default-padding-desktop)
    var(--default-padding-desktop);
  ${media.lessThan("medium")`
    padding: 0 var(--default-padding-mobile) var(--default-padding-mobile) var(--default-padding-mobile);
    grid-template-columns: 1fr 1fr;

  `}
  ${media.lessThan("small")`
    grid-template-columns: 1fr;
  `}
`;
export const TitleWrapper = styled.div``;
export const SearchWrapper = styled.div`
  padding: 2rem var(--default-padding-desktop);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--esx-red);
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;
export const SearchInputLabel = styled.label`
  text-align: center;
  color: #fafafa;
`;
export const InputWrapper = styled.div`
  padding-bottom: 2rem;
  width: 50%;
  display: flex;
  align-items: center;
  margin: auto;
  gap: 1rem;
  ${media.lessThan("medium")`
    width: 100%;

  `}
`;
export const SearchInput = styled.input`
  background-color: #fdfdfd !important;
  color: var(--esx-red) !important;
  padding: 1.5rem !important;
  transition: border 0.5s;
  &::placeholder {
    color: var(--esx-red) !important;
    opacity: 0.8;
  }
  &:focus {
    border: 1px solid var(--esx-red) !important;
  }
`;

export const ItemsWrapper = styled.section`
  color: #fafafa;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem 2rem;
  ${media.lessThan("large")`
  grid-template-columns: 1fr 1fr;
  
  `};
  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}
`;

export const NoResultsAlert = styled.div`
  background: #f2f2f2;
  margin: 1rem var(--default-padding-desktop);
  padding: 1rem;
  text-align: center;
  width: auto;
  color: var(--esx-red);
  overflow-wrap: break-word;
  ${media.lessThan("medium")`
  margin: 1rem var(--default-padding-mobile);
  `}
`;

export const Breadcumbs = styled.div`
  margin: 1rem var(--default-padding-desktop);
  padding: 0.5rem 0;
  width: auto;
  color: var(--esx-red);
  overflow-wrap: break-word;
  display: flex;
  justify-content: space-between;
  p {
    align-items: center;
    color: var(--esx-black);
    display: flex;
    justify-content: baseline;
    padding: 0 0.75em;
  }
  .arrow-links {
    display: flex;
    height: 100%;
    gap: 0.5rem;
    a {
      color: #fafafa;
      border-radius: 5px;
      background-color: var(--esx-red);
      padding: 0.3rem 1rem;
      text-decoration: none;
      transition: color 0.5s;
      &:hover {
        color: var(--esx-red);
      }
    }
  }
  ${media.lessThan("medium")`
    margin: 1rem var(--default-padding-mobile);
  `}
`;

export const BreadcumbLink = styled(Link)``;

export const ChipGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Chip = styled(Link)`
  font-size: var(--label-size);
  color: var(--esx-red);
  border: 1px solid var(--esx-red);
  padding: 0.2rem 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: var(--esx-red);
    color: white;
  }
  &.active-chip {
    background-color: var(--esx-red);
    color: #fafafa;
  }
`;

import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";

import * as S from "./blog-list/styled";

const PostCategorizedList = (props) => {
  return (
    <Layout>
      <h1>Post Category</h1>
    </Layout>
  );
};


export default PostCategorizedList;